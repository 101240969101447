import { VatType, PaymentSystem } from './Enums';

export class ProviderRow {
  id = 0;
  name = '';
  companyName = '';
  companyId = '';
  email = '';
  mobile = '';
  product = '';
  productQty = 0;
  isActive = false;
  pmsLink = '';
}

export class ProvidersFilter {
  pageIndex = 0;
  keyword = '';
  orderBy = 'Name';
  asc = true;

  active: boolean | number | null = 1;
  provider = '';
  providerIdNoHash: number | null = null;
  providerId: string | null = null;
  subscriptionId: number | null = null;
  company = '';
  companyId: number | null = null;
  email = '';
  mobile = '';
  username = '';
  registrationStartDate: string | null = null;
  registrationEndDate: string | null = null;
}

export class ProviderDetails {
  id = 0;
  name = '';
  hash = '';
  address = '';
  email = '';
  mobile = '';
  companyId = '';
  companyName = '';
  nameOfTheBusinessForInvoice = '';
  businessPhoneNumber = '';
  mainImageUrl = '';
  mainImage = '';
  commissionPercent: number | null = null;
  description = '';
  website = '';
  operationContactFullName = '';
  operationContactPhoneNumber = '';
  operationContactEmail = '';
  financeDepartmentFullName = '';
  financeDepartmentPhoneNumber = '';
  financeDepartmentEmail = '';
  subscriptionId: number | null = null;
  smsEmailLanguageId: number | null = null;
  cashierStations: СashierStation[] = [];
  orderSourceOptions: IdText[] = [];
  autoPrintReceipt = false;
  active = false;

  createDate = null;

  notificationsRestrictions: NotificationsRestrictions | null = null;

  users: User[] = [];
  vatType: VatType = VatType.IncVat;
  vatPercent: number | null = null;
  settings: ProviderSetting[] = [
    { id: 0, providerId: 0, key: 'receipt_sequence_start_number', value: '' },
    { id: 0, providerId: 0, key: 'invoice_sequence_start_number', value: '' },
    { id: 0, providerId: 0, key: 'credit_invoice_sequence_start_number', value: '' },
    { id: 0, providerId: 0, key: 'ccard_payment_username', value: '' },
    { id: 0, providerId: 0, key: 'ccard_payment_password', value: '' },
    { id: 0, providerId: 0, key: 'ccard_payment_url', value: '' },
    { id: 0, providerId: 0, key: 'ccard_payment_terminal', value: '' },
    { id: 0, providerId: 0, key: 'ccard_max_count_payments', value: '' },
    { id: 0, providerId: 0, key: 'ccard_payment_system', value: '' },
    { id: 0, providerId: 0, key: 'quantity_payments_credit', value: '' },
  ];

  virtualPhoneNumber = '';
}

export class СashierStation {
  id = 0;
  cashierGuid = '';
  deviceGuid = '';
  stationName = '';
  providerId = '';
  active = false;
}

export class ProviderAccount {
  id = 0;
  bankId: number | null = null;
  bankBranchNumber: number | null = null;
  accountNumber = '';
  accountExternal = '';
  providerAccountCurrencies: number[] = [];
}
