
import { ProviderDetails, СashierStation, ProviderAccount } from '@/models/Provider';
import { computed, defineComponent, PropType, ref, toRaw, watch } from 'vue';
import store from '@/store';
import swal from 'sweetalert2';
import Multiselect from '@vueform/multiselect';
import { useI18n } from 'vue-i18n';
import Users from '@/components/Provider/Users.vue';
import Profiles from '@/components/Provider/Profiles.vue';
import Accounting from '@/components/Provider/Accounting.vue';
import cashierStations from '@/components/Provider/СashierStations.vue';
import NotificationsRestrictions from '@/components/Provider/NotificationsResctrictions.vue';
import InputNumber from 'primevue/inputnumber';
import api from '@/services/Api';
import Q from 'q';
import { PaymentSystem, VatType } from '@/models/Enums';

export default defineComponent({
  name: 'ProviderDetailsComponent',
  components: {
    Multiselect,
    Users,
    Profiles,
    Accounting,
    cashierStations,
    NotificationsRestrictions,
    InputNumber,
  },
  props: {
    modelValue: {
      type: Object as PropType<ProviderDetails>,
      required: true,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    saveWas: {
      type: Boolean,
      default: false,
    },
  },
  async setup(props) {
    const invalidMobile = ref(false);
    const { t } = useI18n();
    const langsSelect = ref({
      valueProp: 'id',
      label: 'name',
      required: true,
      canDeselect: false,
      searchable: false,
      options: computed<Language[]>(() => store.getters.languages),
    });

    const paymentSystem = PaymentSystem;
    const providerPaymentSystems: IdName[] = [];
    for (const enumMember in paymentSystem) {
      const parsedValue = parseInt(enumMember, 10);
      if (parsedValue >= 0) {
        providerPaymentSystems.push({ id: parsedValue.toString(), name: paymentSystem[enumMember] });
      }
    }

    const cCardPaymentSystem = computed<ProviderSetting | undefined>(() =>
      provider.value.settings.find((s: ProviderSetting) => s.key == 'ccard_payment_system'),
    );
    const cCardPaymentUsername = computed<ProviderSetting | undefined>(() =>
      provider.value.settings.find((s: ProviderSetting) => s.key == 'ccard_payment_username'),
    );
    const cCardPaymentPassword = computed<ProviderSetting | undefined>(() =>
      provider.value.settings.find((s: ProviderSetting) => s.key == 'ccard_payment_password'),
    );
    const cCardPaymentTerminal = computed<ProviderSetting | undefined>(() =>
      provider.value.settings.find((s: ProviderSetting) => s.key == 'ccard_payment_terminal'),
    );
    const cCardPaymentUrl = computed<ProviderSetting | undefined>(() =>
      provider.value.settings.find((s: ProviderSetting) => s.key == 'ccard_payment_url'),
    );
    const cCardMaxCountPayments = computed<ProviderSetting | undefined>(() =>
      provider.value.settings.find((s: ProviderSetting) => s.key == 'ccard_max_count_payments'),
    );
    const quantityPaymentsCredit = computed<ProviderSetting | undefined>(() =>
      provider.value.settings.find((s: ProviderSetting) => s.key == 'quantity_payments_credit'),
    );

    function getLangName(langId: number) {
      var result = '';
      if (langId) {
        var findIndex = langsSelect.value.options.findIndex((x) => x.id == langId);
        if (findIndex >= 0) {
          result = langsSelect.value.options[findIndex].name;
        }
      }
      return result;
    }

    function copyModel(value: ProviderDetails) {
      return JSON.parse(JSON.stringify(value)) as ProviderDetails;
    }

    function clearImage() {
      providerLogo.value = null;
      provider.value.mainImageUrl = '';
      provider.value.mainImage = '';
    }

    const providerLogo = ref();
    const uploadingImageValidation = async (e: any) => {
      swal.showLoading();
      let file = e.target.files[0];
      const fileTypes: string[] = ['image/png'];
      if (file) {
        if (file.size > 5242880) {
          swal.fire({
            icon: 'error',
            text: 'File size should be less or equal to 5 MB',
          });
          file = null;
          swal.close();
          return;
        }
        if (!fileTypes.find((t) => t === file.type)) {
          swal.fire({
            icon: 'error',
            text: 'Allowing file type: png',
          });
          file = null;
          return;
        }
        if (typeof FileReader === 'function') {
          const reader = new FileReader();
          reader.onload = (event: any) => {
            providerLogo.value = event.target.result;
            provider.value.mainImage = event.target.result
              .replace('data:image/jpeg;base64,', '')
              .replace('data:image/png;base64,', '');
          };
          reader.readAsDataURL(file);
        }
      }
      swal.close();
    };

    const provider = ref<ProviderDetails>(copyModel(props.modelValue));
    watch(
      () => props.modelValue,
      (newValue) => {
        provider.value = copyModel(newValue);
      },
    );
    const subscriptions = computed(() => store.state.subscriptions);
    const selectedSubscriptions = computed(() => store.getters.getSubscription(provider.value.subscriptionId) || {});
    const banks: IdName[] = await store.dispatch('getBanks');
    const currencies = computed<Currency[]>(() => store.getters.currencies);

    function getProvider() {
      return toRaw(provider.value);
    }

    function UpdateProviderCommission(value: number) {
      provider.value.commissionPercent = Number(value);
    }

    function updateVat(value: number) {
      provider.value.vatPercent = Number(value);
    }

    function UpdateVatType(value: number) {
      switch (value) {
        case 0:
          provider.value.vatType = VatType.IncVat;
          break;
        case 1:
          provider.value.vatType = VatType.NoVat;
          break;
        default:
          provider.value.vatType = VatType.NoVat;
          break;
      }
    }

    function addStation(item: СashierStation) {
      provider.value.cashierStations.push(item);
    }

    function deleteStation(item: СashierStation){
      const index = provider.value.cashierStations.findIndex(station => station.id === item.id);
      if (index !== -1) {
        provider.value.cashierStations.splice(index, 1);
      }
    }
    

    function getBankName(bankId: number | null) {
      if (!bankId) {
        return '';
      }
      return banks.find((x) => x.id == bankId.toString())?.name ?? '';
    }

    function getCurrenciesToShow(ids: number[]) {
      return currencies.value
        .filter((x) => ids.includes(x.id))
        .map((x) => x.symbol)
        .join(', ');
    }

    async function disactivateOrActivateProvider(evt: Event) {
      evt.preventDefault();
      const isActive = provider.value.active;
      let providerActivateOrDisactivateMessage = isActive ? t('provider.deactivate') : t('provider.activate');
      const swalAction = await swal.fire({
        icon: 'info',
        text: providerActivateOrDisactivateMessage,
        customClass: {
          confirmButton: isActive
            ? 'stop-sale-button popup-bookit-button my-1 px-4'
            : 'green-button popup-bookit-button my-1 px-4',
          cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5',
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: isActive ? t('alert.yesDeactivate') : t('alert.yesActivate'),
        cancelButtonText: t('button.cancel'),
      });
      if (!swalAction.isConfirmed) {
        return;
      }
      await Q.delay(200);
      swal.showLoading();
      const response = await api.DisactivateOrActivateProvider(provider.value.id);
      if (response.errorMessage) {
        swal.fire({
          icon: 'error',
          text: response.errorMessage,
        });
        return;
      }
      provider.value.active = !provider.value.active;
      swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Provider updated',
        showConfirmButton: false,
        timer: 1500,
      });
    }

    function updateInvalidMobileValue(newvalue: boolean) {
      invalidMobile.value = newvalue;
    }

    function addOption() {
      provider.value.orderSourceOptions.push({
        id: 0,
        text: '',
      });
    }
    async function deleteSourceOption(index: number) {
      const swalResult = await swal.fire({
        icon: 'info',
        title: t('editProviderPage.removeProviderOrderSourceAlert'),
        customClass: {
          confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-5',
          cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5',
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: t('button.yes'),
        cancelButtonText: t('button.no'),
      });
      if (swalResult.isConfirmed) {
        provider.value.orderSourceOptions.splice(index, 1);
      }
    }
    function changeReceiptProviderSetting(newValue: boolean) {
      provider.value.autoPrintReceipt = newValue;
    }
    return {
      provider,
      subscriptions,
      selectedSubscriptions,
      getProvider,
      providerLogo,
      uploadingImageValidation,
      clearImage,
      langsSelect,
      getLangName,
      UpdateProviderCommission,
      addStation,
      banks,
      currencies,
      getBankName,
      getCurrenciesToShow,
      disactivateOrActivateProvider,
      invalidMobile,
      updateInvalidMobileValue,
      addOption,
      deleteSourceOption,
      cCardPaymentSystem,
      providerPaymentSystems,
      paymentSystem,
      cCardPaymentUsername,
      cCardPaymentPassword,
      cCardPaymentTerminal,
      cCardPaymentUrl,
      cCardMaxCountPayments,
      quantityPaymentsCredit,
      UpdateVatType,
      changeReceiptProviderSetting,
      deleteStation,
      updateVat
    };
  },
});
